<template>
    <v-menu
      v-model="menu"
      :close-on-content-click="true"
      bottom left
      offset-y
      max-height="400"
    >
        <template v-slot:activator="{ on }">
            <v-btn text icon v-on="on">
                <v-badge
                    :content="unreadNotifications.length"
                    :value="unreadNotifications.length"
                    color="green"
                    overlap
                >
                    <v-icon>mdi-bell-outline</v-icon>
                </v-badge>
            </v-btn>
        </template>

        <v-card>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Notificaciones</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
                <v-list-item-group color="primary">
                    <v-list-item v-bind:key="n.id" v-for="n in notifications" @click="onNotificationSelected(n)">
                        <v-list-item-action>
                            <v-badge :value="!n.isRead" color="blue" dot></v-badge>
                        </v-list-item-action>

                        <v-list-item-content>
                            <!-- <v-list-item-title><span v-if="n.createdBy" class="font-weight-bold">{{ n.createdBy.name }}</span> {{ n.message }}</v-list-item-title> -->
                            <v-list-item-title>{{ getMessageForNotification(n) }}</v-list-item-title>
                            <v-list-item-subtitle>{{ getMessageDetailsForNotification(n) }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action class="caption grey--text">
                            {{ n.createdAt | moment('from', 'now') }}
                        </v-list-item-action>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <v-card-actions>
                <!-- <v-spacer></v-spacer>

                <v-btn text @click="menu = false">Cancel</v-btn>
                <v-btn color="primary" text @click="menu = false">Save</v-btn> -->
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        components: {

        },

        data() {
            return {
                menu: false,
                offsetY: 50
            };
        },

        computed: {
            user() {
                return this.$store.getters['user/get'];
            },

            notifications() {
                return this.$store.getters['user/getNotifications'];
            },

            unreadNotifications() {
                if(!this.notifications) {
                    return [];
                }

                return this.notifications.filter(n => !n.isRead);
            }
        },

        watch: {
            menu: {
                handler(val) {
                    // when hiding the menu, mark as read
                    if(!val) {
                        this.$store.dispatch('user/markNotificationsAsRead', this.unreadNotifications);
                    }
                }
            }
        },

        methods: {
            getMessageForNotification(notification) {
                const [ resourceType, resourceId ] = notification.resource.split(':');

                let params = {
                    createdBy: notification.createdBy ? notification.createdBy.name : null,
                    resourceType: resourceType,
                    resourceId: resourceId,
                    details: notification.details
                };

                let msg = notification.message.split('\\n')[0];
                msg = this.$t(msg);

                Object.keys(params).forEach(function(key) {
                    msg = msg.replace('$' + key, params[key]);
                });

                return msg;
            },

            getMessageDetailsForNotification(notification) {
                let msg = notification.message.split('\\n');
                if (msg.length > 1) {
                    return msg[1];
                }

                return '';
            },

            onNotificationSelected(notification) {
                const [ resourceType, resourceId ] = notification.resource.split(':');

                switch(resourceType) {
                    case 'ticket':
                        this.$router.push({ name: 'claimView', params: { id: resourceId } });
                        break;

                    case 'order':
                        this.$router.push({ name: 'orderView', params: { id: resourceId } });
                        break;

                    default: 
                        break;
                }
            }
        }
    };
</script>
