<template>
    <div class="template-layout">
        <v-snackbar
            class="hidden-print-only"
            v-model="showSnackbar"
            color="green"
            :timeout="1500"
            top
        >
            {{ snackbarMessage }}
        </v-snackbar>

        <v-system-bar class="hidden-print-only" v-if="!isProductionEnv && !isLocalEnv" dark color="orange" app><span>Entorno de pruebas</span></v-system-bar>
        <v-system-bar class="hidden-print-only" v-else-if="isLocalEnv" dark color="red" app><span>Entorno local</span></v-system-bar>

        <v-app-bar
            app
            class="elevation-0 hidden-print-only main-toolbar"
            clipped-left
            clipped-right
            color="white"
        >
            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click.stop="showNavigation = !showNavigation"></v-app-bar-nav-icon>

            <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp">
                <v-img v-if="$vuetify.theme.dark" :src="require('../assets/logo-black.png')" contain height="46"></v-img>
                <v-img v-else :src="require('../assets/logo-black.png')" contain height="46"></v-img>
            </v-toolbar-title>

            <v-col cols="3" v-if="$vuetify.breakpoint.smAndUp">
                <tallo-multi-search-field></tallo-multi-search-field>
            </v-col>

            <v-spacer></v-spacer>

            <v-btn text icon :to="{ name: 'orderCreate' }">
                <v-icon>mdi-cart-outline</v-icon>
            </v-btn>

            <v-divider :inset="true" :vertical="true"></v-divider>

            <!-- <v-btn v-if="$vuetify.breakpoint.smAndUp" text icon @click="onToggleDarkModeSelected">
                <v-icon>mdi-brightness-4</v-icon>
            </v-btn> -->

            <notification-menu></notification-menu>

            <v-col class="flex-grow-0 px-0">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" class="pa-0">
                            <v-list-item-avatar class="mx-0">
                                <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
                            </v-list-item-avatar>

                            <v-list-item-content v-if="$vuetify.breakpoint.smAndUp" class="ml-4 pr-0">
                                <v-list-item-title>{{ userDisplayName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ userRole }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list>
                        <v-list-item @click="onLogoutSelected">
                            <v-list-item-action>
                                <v-icon>mdi-power-settings</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-app-bar>

        <v-navigation-drawer
            class="hidden-print-only"
            app clipped
            v-model="showNavigation"
            :expand-on-hover="$vuetify.breakpoint.mdAndUp"
            :permanent="$vuetify.breakpoint.mdAndUp"
            :temporary="$vuetify.breakpoint.smAndDown"
            :hide-overlay="showSubSection || $vuetify.breakpoint.mdAndUp"
            color="background"
            :mini-variant="$vuetify.breakpoint.mdAndUp"
            @update:mini-variant="onMiniVariantUpdated"
        >
            <v-list nav dense flat class="pa-0">
                <template v-for="page in pages">
                    <template v-if="hasPermissions(page.roles)">
                        <v-list-item v-if="!page.children" link :to="{ name: page.route }" exact v-bind:key="page.name">
                            <v-list-item-icon class="pl-2">
                                <v-icon>{{ page.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ page.name }}</v-list-item-title>
                        </v-list-item>

                        <v-list-group v-else v-model="page.active" v-bind:key="page.name" no-action>
                            <template v-slot:activator>
                                <v-list-item-icon class="pl-2">
                                    <v-icon>{{ page.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="page.name">{{ page.name }}</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item
                                v-for="page in page.children"
                                :key="page.route"
                                :to="{ name: page.route }" exact
                            >
                                <v-list-item-content>
                                    <v-list-item-title v-text="page.name"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </template>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-main class="hidden-screen-only pa-2 ma-0">
            <slot name="print"></slot>
        </v-main>

        <v-main style="min-height: 100vh;" class="hidden-print-only">
            <slot name="drawers"></slot>
            <slot name="content"></slot>
        </v-main>
    </div>
</template>

<script>
    import TalloMultiSearchField from '@/components/TalloMultiSearchField';
    import NotificationMenu from './NotificationMenu';

    export default {
        name: 'TemplateLayout',

        components: {
            TalloMultiSearchField,
            NotificationMenu
        },

        data() {
            return {
                showSnackbar: false,
                snackbarMessage: null,

                selectedSection: null,
                showSubSection: false,
                showNavigation: false,

                pages: [
                    { name: 'Dashboard', icon: 'mdi-account-circle', route: 'dashboard', roles: [ 'ROLE_ADMIN' ] },
                    {
                        name: 'Pedidos',
                        icon: 'mdi-cart-outline',
                        route: 'orderList',
                        roles: [ 'ROLE_CALLCENTER', 'ROLE_ADMIN' ]
                    },
                    {
                        name: 'Envios',
                        icon: 'mdi-package',
                        route: 'shippingList',
                        roles: [ 'ROLE_ADMIN', 'ROLE_HUERTA', 'ROLE_CALLCENTER' ]
                    },
                    {
                        name: 'Clientes',
                        icon: 'mdi-account-outline',
                        route: 'customerList',
                        roles: [ 'ROLE_CALLCENTER', 'ROLE_ADMIN' ]
                    },
                    {
                        name: 'Tickets',
                        icon: 'mdi-lifebuoy',
                        route: 'claimList',
                        roles: [ 'ROLE_CALLCENTER', 'ROLE_ADMIN' ]
                    },
                    {
                        name: 'Cosecha',
                        icon: 'mdi-spa-outline',
                        route: 'harvestList',
                        roles: [ 'ROLE_ADMIN', 'ROLE_HUERTA', 'ROLE_LPQ' ]
                    },
                    {
                        name: 'Armado',
                        icon: 'mdi-package-variant',
                        route: 'packingList',
                        roles: [ 'ROLE_ADMIN', 'ROLE_HUERTA' ]
                    },
                    {
                        name: 'Despacho',
                        icon: 'mdi-dolly',
                        route: 'dispatchList',
                        roles: [ 'ROLE_ADMIN', 'ROLE_HUERTA' ]
                    },
                    {
                        name: 'Logistica',
                        icon: 'mdi-truck-outline',
                        route: 'deliveryRoutesList',
                        roles: [ 'ROLE_ADMIN', 'ROLE_CHOFER', 'ROLE_HUERTA', 'ROLE_CALLCENTER' ]
                    },
                    {
                        name: 'Efectivo',
                        icon: 'mdi-cash',
                        route: 'cashAccountList',
                        roles: [ 'ROLE_ADMIN', 'ROLE_CHOFER' ]
                    },
                    {
                        name: 'Facturación',
                        icon: 'mdi-currency-usd',
                        route: 'invoiceList',
                        roles: [ 'ROLE_ADMIN' ],
                    },
                    {
                        name: 'Productos',
                        icon: 'mdi-package',
                        route: 'productList',
                        roles: [ 'ROLE_ADMIN', 'ROLE_HUERTA' ]
                    },
                    {
                        name: 'Producción',
                        icon: 'mdi-factory',
                        route: 'inventoryList',
                        roles: [ 'ROLE_ADMIN' ],
                    },
                    {
                        name: 'Reportes',
                        icon: 'mdi-chart-line',
                        roles: [ 'ROLE_ADMIN' ],
                        route: 'ordersHeatMap'
                    },
                    {
                        name: 'Administración',
                        icon: 'mdi-tune',
                        route: 'parametersList',
                        roles: [ 'ROLE_ADMIN' ],
                    }
                ]
            };
        },

        computed: {
            isProductionEnv() {
                return process.env.VUE_APP_ENVIRONMENT === 'prod';
            },

            isLocalEnv() {
                return process.env.VUE_APP_ENVIRONMENT === 'local';
            },

            statusMessage() {
                return this.$store.getters['app/getStatusMessage']();
            },

            hasPrintSlot() {
                return !!this.$slots.print;
            },

            userDisplayName() {
                let user = this.$store.getters['user/get'];
                if(user) {
                    return user.firstName;
                }

                return 'N/A';
            },

            userRole() {
                if(this.$store.getters['user/hasRole']('ROLE_ADMIN')) {
                    return 'Administrador';
                }
                else if(this.$store.getters['user/hasRole']('ROLE_CHOFER')) {
                    return 'Delivery';
                }
                else if(this.$store.getters['user/hasRole']('ROLE_CALLCENTER')) {
                    return 'Call Center';
                }
                else if(this.$store.getters['user/hasRole']('ROLE_HUERTA')) {
                    return 'Huerta';
                }
                else {
                    return '';
                }
            }
        },

        watch: {
            statusMessage(val) {
                if(val !== '') {
                    this.showSnackbar = true;
                    this.snackbarMessage = val;
                    this.$store.dispatch('app/setStatusMessage', '');
                }
            },

            selectedSection(val) {
                if(val) {
                    this.showSubSection = true;
                }
            },

            showSubSection(val) {
                if(!val) {
                    this.selectedSection = null;
                }
            }
        },

        timers: {
            notificationsTimer: {
                time: 30000,
                autostart: true,
                repeat: true
            }
        },

        methods: {
            onMiniVariantUpdated(val) {
                for(let page of this.pages) {
                    page.active = false;
                }
            },

            notificationsTimer() {
                this.$store.dispatch('user/fetchNotifications');
            },

            isActiveMenu(page) {
                if(this.selectedSection === page) {
                    return true;
                }

                if(this.$router.currentRoute.path.startsWith(page.route)) {
                    return true;
                }

                return false;
            },

            onToggleDarkModeSelected() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            },

            onLogoutSelected() {
                this.$store.dispatch('user/logout');
                this.$router.push('/login');
            },

            hasPermissions(roles) {
                if(!roles) {
                    return true;
                }

                for(let role of roles) {
                    if(this.$store.getters['user/hasRole'](role)) {
                        return true;
                    }
                }

                return false;
            }
        }
    };
</script>

<style lang="scss">
    .v-subheader {
        padding-left: 0;
        font-size: 0.9rem;
    }

    .v-toolbar--dense {
        .v-toolbar__content {
            padding: 0;
        }
    }

    .t-info-list {
        .v-list-item {
            min-height: 30px;
            padding: 0;

            .v-list-item__content {
                padding: 0;

                &:first-child {
                    max-width: 200px;
                }

                &:last-child {
                    justify-content: flex-end !important;
                    font-weight: 500;
                }
            }
        }
    }

    .v-navigation-drawer {
        .v-list {
            .v-list-item {
                margin-top: 15px;
                margin-bottom: 15px;
            }

            .v-list-item--active {
                border-left: 2px solid var(--v-primary-base);
                border-radius: 0;

                &::before {
                    opacity: 0;
                }

                .v-list-item__action {
                    .v-icon {
                        color: var(--v-primary-base);
                    }
                }
            }
        }

        .footer {
            padding: 0;
            margin: 0;
            padding-right: 50px;
            position: absolute;
            bottom: 0;
        }
    }

    .v-tabs {
        .v-tabs-bar {
            background-color: transparent !important;
        }

        .v-tabs-items {
            background-color: transparent !important;
        }
    }

    .v-data-table {
        background-color: transparent !important;

        .v-row-group__header {
            background-color: transparent !important;

            td {
                border-bottom: 1px solid #f0eff2 !important;
            }
        }

        tbody {
            tr {
                td {
                    border: none !important;
                }
            }
        }
    }

    .theme--dark {
        .filters-row {
            background-color: #e5e9f2 !important;
            border-radius: 5px;
        }

        .v-toolbar {
            background-color: #2b2e44;
            // border-bottom: 1px solid #32354b !important;
        }

        &.v-navigation-drawer {
            .v-navigation-drawer__border {
                background-color: #32354b !important;
            }

            .v-list {
                .v-list-item--active {
                    color: #ffffff !important;
                }
            }

            .v-list-group {
                &.v-list-group--active {
                    border: none;
                    background-color: #343c44;

                    &::before {
                        background: none;
                    }

                    &::after {
                        background: none;
                    }
                }
            }
        }
    }

    .theme--light {
        &.v-app-bar.v-toolbar.v-sheet {
            background-color: white;
            border-bottom: 1px solid #edf0ef !important;
        }

        .filters-row {
            background-color: #e5e9f2 !important;
            border-radius: 5px;
            margin-bottom: 40px;
        }

        .v-icon {
            color:rgba(0, 0, 0, .87);
        }

        &.v-navigation-drawer {
            .v-navigation-drawer__border {
                background-color: #edf0ef !important;
            }

            .v-list {
                color: #afb5b9;

                .v-list__tile__title {
                    font-size: 1.0rem;
                }

                .v-list__tile--active {
                    color: #82888f !important;
                }

                .v-icon {
                    color: #afb5b9;
                }
            }

            .footer {
                border-top: 1px solid #edf0ef;
                // background-color: #ffffff;
            }
        }

        // This doesn't work properly for adding green border on the left, screws with vuetify effect
        // .v-expansion-panel-header {
        //     &.v-expansion-panel-header--active {
        //         font-size: 3.25rem;
        //         font-weight: 400;
        //         letter-spacing: 0.0125em;
        //         line-height: 2rem;
        //         color: rgba(0,0,0,.8);
        //     }

        //     &.v-expansion-panel-header--active::before {
        //         content: '';
        //         position: absolute;
        //         background-color: var(--v-primary-base);
        //         width: 2px;
        //         height: 30px;
        //         opacity: 1;
        //         top: unset;
        //         bottom: unset;
        //         right: unset;
        //         left: unset;
        //         top: 10 !important;
        //         left: 0;
        //     }
        // }

        .v-card {
            border: none;
            border-radius: 3px;
            background-clip: padding-box;

            &.v-card:not(.v-sheet--outlined) {
                box-shadow: none;
            }

            .v-card__title {
                font-size: 1.25rem;
                font-weight: 400;
                letter-spacing: 0.0125em;
                line-height: 2rem;
                color: rgba(0,0,0,.8);
            }

            .v-card__title::before {
                content: '';
                position: absolute;
                background-color: var(--v-primary-base);
                width: 2px;
                height: 30px;
                top: 10;
                left: 0;
            }

            .t-card-separator {
                &:first-child {
                    border-top: none;
                }

                min-height: 48px;
                border-top: 1px solid #e5e9f2;
                border-bottom: 1px solid #e5e9f2;
                background-color: var(--v-background-base);
                padding-left: 20px;
                padding-right: 20px;
                color: black;

                span {
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 0.8rem;
                }
            }
        }

        &.v-tabs {
            border-top-left-radius: 6px !important;
            border-top-right-radius: 6px !important;
        }

        &.v-tabs__bar {
            background-color: transparent;
        }
    }

    @media print {
        .content-wrapper {
            position: relative;
            top: 0;
        }

        .v-content {
            padding: 0 !important;
        }

        nav, aside {
            display: none !important;
        }
    }
</style>
