<template>
    <v-autocomplete
        flat solo hide-details prepend-icon="mdi-magnify" append-icon=""
        placeholder="Buscar..."
        :items="searchResults"
        v-model="selectedSearchResult"
        :loading="searchIsLoading"
        :search-input.sync="search"
        no-filter
        hide-no-data
        return-object
        auto-select-first
    >
        <template v-slot:item="item" v-if="searchType === 'customers'">
            <v-list-item-avatar
                v-if="item.item.fullName"
                color="indigo"
                class="headline font-weight-light white--text"
            >
                {{ item.item.fullName.charAt(0) }}
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title v-text="item.item.fullName"></v-list-item-title>
                <v-list-item-subtitle v-text="item.item.id"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
<!--                <v-icon>mdi-coin</v-icon>-->
            </v-list-item-action>
        </template>

        <template v-slot:item="item" v-else-if="searchType === 'orders'">
            <v-list-item-content>
                <v-list-item-title>{{ getCustomer(item.item.customer).fullName }}</v-list-item-title>
                <v-list-item-subtitle># {{ item.item.id }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <tui-order-state-chip :state="item.item.state"></tui-order-state-chip>
            </v-list-item-action>
        </template>
    </v-autocomplete>
</template>

<script>
    import TuiOrderStateChip from '@/ui/TUIOrderStateChip';

    export default {
        components: { TuiOrderStateChip },

        data() {
            return {
                search: '',
                searchResults: [],
                searchIsLoading: false,
                selectedSearchResult: null
            };
        },

        timers: {
            fetchTimer: {
                time: 400,
                autostart: false
            }
        },

        watch: {
            search: {
                handler(val) {
                    this.searchResults = [];
                    this.selectedSearchResult = null;

                    if(!val || val === '') {
                        return;
                    }

                    this.$timer.stop('fetchTimer');
                    this.$timer.start('fetchTimer');
                }
            },

            selectedSearchResult: {
                handler(val) {
                    if(!val || val === '') {
                        return;
                    }

                    if(this.searchType === 'customers') {
                        this.$router.push({ name: 'customerView', params: { id: val.id } }).catch(err => {});
                    }
                    else if(this.searchType === 'orders') {
                        this.$router.push({ name: 'orderView', params: { id: val.id } }).catch(err => {});
                    }

                    this.search = '';
                }
            }
        },

        computed: {
            searchType() {
                if(!this.search) {
                    return 'none';
                }

                if(isNaN(this.search)) {
                    return 'customers';
                }
                else {
                    return 'orders';
                }
            }
        },

        methods: {
            getCustomer(id) {
                return this.$store.getters['customer/getById'](id);
            },

            async fetchTimer() {
                this.$timer.stop('fetchTimer');

                if(!this.search || this.search === '') {
                    return;
                }

                this.searchIsLoading = true;

                if(!isNaN(this.search)) {
                    try {
                        let result = await this.$store.dispatch('order/fetchAll', { search: this.search.trim() });
                        this.searchResults = result.ids.map(id => this.$store.getters['order/getById'](id));
                    }
                    finally {
                        this.searchIsLoading = false;
                    }
                }
                else {
                    try {
                        let result = await this.$store.dispatch('customer/fetchAll', { search: this.search.trim() });
                        this.searchResults = result.ids.map(id => this.$store.getters['customer/getById'](id));
                    }
                    finally {
                        this.searchIsLoading = false;
                    }
                }
            }
        }
    };
</script>
