<template>
    <v-chip
        label
        x-small-chip
        :color="stateColor"
        text-color="white"
    >
        {{ stateName }}
    </v-chip>
</template>

<script>
    export default {
        name: 'tui-order-state-chip',

        props: {
            state: {
                type: String,
                default: ''
            },

            shrink: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            stateName() {
                let name = this.state;

                switch (this.state) {
                case 'state.open':
                    name = 'Pendiente';
                    break;
                case 'state.closed':
                    name = 'Completado';
                    break;
                case 'state.cancelled':
                    name = 'Cancelado';
                    break;
                }

                if(this.shrink && this.$vuetify.breakpoint.smAndDown) {
                    name = name.charAt(0);
                }

                return name;
            },

            stateColor() {
                switch (this.state) {
                case 'state.closed':
                    return 'green';
                case 'state.open':
                    return 'blue';
                case 'state.cancelled':
                    return 'red';
                default:
                    return '';
                }
            }
        }
    };
</script>
